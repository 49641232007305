import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Lightbox } from "react-modal-image";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PolicyConfirmModal from './PolicyConfirmModal';
import RoomListModal from './../Room/RoomListModal';
import { AppContext } from "../context/ContextProvider";
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import _ from "lodash";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactGA from 'react-ga4';

moment.locale('th')

const BookingModal = ({ show, onHide = () => { } }) => {
  const navigate = useNavigate();
  const { roomSelected, currentHotel, bookStartDate, bookEndDate, bookingData, handleSetRoomSelected } = useContext(AppContext);

  const [form, setForm] = useState({});
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showConfrimModal, setShowConfrimModal] = useState(false);
  const [showRoomList, setShowRoomList] = useState(false)
  const [acceptPdpa, setAcceptPdpa] = useState(false)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(null);

  const [addons, setAddons] = useState([]);
  const [addonOptions, setAddonsOptions] = useState([]);

  const bookingDatesStr = bookingData.map((b) => `${moment(b.date).format('DD/MM/YYYY')} (${numberFormat(b.price, true)})`).join(', ')

  const a = moment(new Date(moment(bookEndDate).format('YYYY-MM-DD')));
  const b = moment(new Date(moment(bookStartDate).format('YYYY-MM-DD')));
  const dayQty = a.diff(b, 'days') || 0
  const totalPrice = _.sumBy(bookingData, (o) => Number(o.price || 0)) || 0

  let discountPrice = 0

  if (discount && discount?.coupon) {
    const c = discount?.coupon
    const couponDiscountPrice = Number(c.discount_price) || 0

    if (c.discount_type === 'static') {
      discountPrice = couponDiscountPrice
    } else {
      discountPrice = (Number(totalPrice || 0) || 0) * (couponDiscountPrice / 100)
    }

    if (discountPrice > Number(totalPrice)) {
      discountPrice = Number(totalPrice)
    }

    if (c.discount_type === 'percent') {
      const dayLimit = Number(c.discount_day_limit || 0)
      const discountLimit = Number(c.discount_price_limit || 0)

      if (dayLimit > 0 && dayLimit < dayQty) {
        let discountPerDays = []
        bookingData.map((_b, _i) => {
          if (_i >= dayLimit) {
            return
          }

          discountPerDays.push((Number(_b.price) || 0) * (couponDiscountPrice / 100) || 0)
        })

        discountPrice = _.sum(discountPerDays) || 0
      }

      if (discountLimit > 0 && discountPrice > discountLimit) {
        discountPrice = discountLimit
      }
    }
  }

  const sumAddons = _.sumBy(addons || [], (x) => { return Number(x.total_price || 0) }) || 0;

  const depositConfigType = roomSelected?.deposit_enabled ? 'room' : (currentHotel?.deposit_enabled ? 'hotel' : null)

  const depositEnabled = !!depositConfigType
  const depositeType = depositConfigType === 'room' ? roomSelected?.deposit_type : currentHotel?.deposit_type
  const depositValue = depositConfigType === 'room' ? (roomSelected?.deposit_value || 0) : (currentHotel?.deposit_value || 0)

  const depositPrice = depositeType === 'static' ? depositValue : (Number(totalPrice) * (depositValue / 100)) || 0

  const addonPriceForPay = (form.payment_type !== 'deposit' || !currentHotel?.deposit_addon_enabled) ? sumAddons : (depositeType === 'static' ? 0 : (Number(sumAddons) * (depositValue / 100)))

  const allowPayOnStay = !!currentHotel?.allow_payonstay

  const roomDepositEnabled = roomSelected?.room_deposit_enabled || currentHotel?.room_deposit_enabled || false
  const roomDepositPrice = roomDepositEnabled ? (roomSelected?.room_deposit_price || currentHotel?.room_deposit_price || 0) : 0

  const payAmount = (form.payment_type === 'full' ? (Number(totalPrice || 0) - discountPrice + sumAddons) : (form.payment_type === 'payonstay' ? (sumAddons || 0) : (Number(depositPrice || 0) + Number(addonPriceForPay)))) + Number(roomDepositPrice || 0)
  // const payAmount = (form.payment_type === 'full' ? (Number(totalPrice || 0) - discountPrice + sumAddons) : (form.payment_type === 'payonstay' ? (sumAddons || 0) : (Number(depositPrice || 0) + sumAddons))) + Number(roomDepositPrice || 0)

  const fetchHotelAddonsItem = (hId) => {
    api.request(`hotel-addons/all`, (data) => {
      setAddonsOptions(data.map((item) => {
        return {
          label: item.name_th,
          value: item.id,
          _price: item.default_price || 0
        }
      }))
    }, { hotel_id: roomSelected.hotel_id, enabled: true, is_non_inventory: true })
  }

  const validateAddons = () => {
    if (!addons.length) {
      return true
    }

    let l = (addons.filter((x) => !!x.addons_id)) || []

    return l.length === addons.length
  }

  useEffect(() => {
    if (!show) {
      setForm({})
      return
    }

    setForm({
      ...form,
      payment_type: 'full',
      payment_state: 'waiting',
      state: 'payment',
      booking_date: new Date(),
      start_date: bookStartDate,
      end_date: bookEndDate,
      hotel_id: roomSelected.hotel_id,
      room_id: roomSelected.id,
      // unit_price: roomSelected.price || 0, // todo fix this
      // unit_vat_price: roomSelected.vat_price || 0, // todo fix this
      // unit_total_price: roomSelected.total_price || 0, // todo fix this
      // total_price: roomSelected.total_price || 0, // todo fix this
      booking_note: '',
      slip_file: null
    })

    fetchHotelAddonsItem()

  }, [show])

  useEffect(() => {
    if (form?.payment_type !== 'full') {
      setCouponCode('')
      setDiscount(null)
    }

  }, [form?.payment_type])


  return (
    <>
      <Modal size="lg" show={show} onHide={() => onHide(true)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>รายการจอง : {roomSelected?.name_th} ({roomSelected?.suggestion_guest_info_th}) </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div> <strong>วันที่เข้าพัก : </strong><span className="ms-2 text-success">{moment(bookStartDate).format('DD/MM/YYYY')} - {moment(bookEndDate).format('DD/MM/YYYY')} ({dayQty} คืน)</span></div>
          <div><span className="text-muted">ราคาห้องพัก : {bookingDatesStr}</span></div>
          <h5> <strong>ราคารวม : </strong><span className="ms-2 text-success">{numberFormat(totalPrice)} บาท</span></h5>
          {roomDepositPrice > 0 && <div><span className="text-danger">เงินประกันห้องพัก : {numberFormat(roomDepositPrice, true)} บาท (คืนลูกค้าหลังการเข้าพัก)</span></div>}
          <hr style={{ color: 'black' }} />
          {((depositEnabled && depositValue > 0) || allowPayOnStay) && <div className="deposit-booking-form">
            <strong>ตัวเลือกการชำระเงิน : </strong><small className="text-muted">กรณีใช้คูปองส่วนลดจะต้องชำระเต็มจำนวนเท่านั้น</small>
            <div className="d-block d-md-flex">
              <label className="form-check  ms-2 ms-md-0">
                <input type="radio" className="form-check-input" checked={form.payment_type === 'full'} onChange={(e) => setForm({ ...form, payment_type: 'full' })} name="paymentType" />
                <span>ชำระเต็มจำนวน</span>
              </label>
              {(depositEnabled && depositValue > 0) && <label className="form-check ms-2">
                <input type="radio" className="form-check-input" checked={form.payment_type === 'deposit'} onChange={(e) => setForm({ ...form, payment_type: 'deposit' })} name="paymentType" />
                <span>มัดจำ{depositeType === 'percent' && <span> {numberFormat(depositValue, true)}%</span>} ({numberFormat(depositPrice)} บาท)</span>
              </label>}
              {allowPayOnStay && <label className="form-check ms-2">
                <input type="radio" className="form-check-input" checked={form.payment_type === 'payonstay'} onChange={(e) => setForm({ ...form, payment_type: 'payonstay' })} name="paymentType" />
                <span>ชำระวันเข้าพัก {roomDepositPrice > 0 && <small className="text-danger">(ชำระเงินประกันห้องพักเมื่อจอง และชำระค่าเช่าในวันเข้าพัก)</small>}</span>
              </label>}
            </div>
          </div>}
          <div className="booking-form-input">
            {form.payment_type === 'full' && <div className="mt-2">
              <strong className="text-warning">ส่วนลด/คูปอง (ถ้ามี)</strong>

              {!discount && <Row className="mb-1">
                <Col xs={8}>
                  <input
                    type="text"
                    name="coupon_code"
                    placeholder="ระบุโค้ดส่วนลด"
                    value={couponCode || ""}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                </Col>
                <Col xs={4}>
                  <Button disabled={!couponCode?.length} className="btn btn-sm btn-success" onClick={() => {
                    setDiscount(null)

                    api.request(`coupons/items/${couponCode}`, (data, suc) => {
                      if (!suc) {
                        return
                      }

                      if (data.error) {
                        toast.warn(data.message)
                        return
                      }
                      setDiscount(data.coupon)
                    }, {})
                  }}>
                    ใช้คูปอง
                  </Button>
                </Col>
              </Row>}
              {!!discount && <Row className="mb-1">
                <Col xs={8}>
                  <strong className="text-success"> ส่วนลดที่ได้รับ : {numberFormat(discountPrice)} บาท</strong>
                </Col>
                <Col xs={4}>
                  <Button className="btn btn-sm btn-danger" onClick={() => {
                    setCouponCode('')
                    setDiscount(null)
                  }}>
                    ยกเลิกคูปอง
                  </Button>
                </Col>
              </Row>}
            </div>}
            <Row className="mt-4">
              <Col xs={6}>
                <input
                  type="text"
                  placeholder="จำนวนผู้เข้าพัก"
                  value={`เข้าพักจำนวน ${dayQty} คืน`}
                  readOnly
                />
              </Col>
              <Col xs={6}>
                <input
                  type="number"
                  name="guest_qty"
                  placeholder="จำนวนผู้เข้าพัก"
                  min={1}
                  onKeyPress={inputKeyPressOnlyNumber} value={form.guest_qty || ""}
                  onChange={(e) => setForm({ ...form, guest_qty: e.target.value })}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12}>
                <input
                  type="text"
                  name="booking_contact_name"
                  placeholder="ระบุชื่อผู้ติดต่อ"
                  value={form.contact_name || ""}
                  onChange={(e) => setForm({ ...form, contact_name: e.target.value })}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={6}>
                <input
                  type="text"
                  name="booking_tel_1"
                  placeholder="เบอร์ติดต่อ 1"
                  onKeyPress={inputKeyPressOnlyNumber} value={form.contact_tel_1 || ""}
                  onChange={(e) => {
                    if (e.nativeEvent?.inputType === "insertFromPaste" && e.target.value) {
                      const t = e.target.value.replace(/\D/g, '')
                      setForm({ ...form, contact_tel_1: t })
                    } else {
                      setForm({ ...form, contact_tel_1: e.target.value })
                    }
                  }}
                  required
                />
              </Col>
              <Col xs={6}>
                <input
                  type="text"
                  name="booking_tel_2"
                  placeholder="เบอร์ติดต่อ 2"
                  onKeyPress={inputKeyPressOnlyNumber} value={form.contact_tel_2 || ""}
                  onChange={(e) => setForm({ ...form, contact_tel_2: e.target.value })}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={6}>
                <input
                  type="text"
                  name="contact_email"
                  placeholder="อีเมล"
                  value={form.contact_email || ""}
                  onChange={(e) => setForm({ ...form, contact_email: e.target.value })}
                />
              </Col>
              <Col xs={6}>
                <input
                  type="text"
                  name="contact_line_id"
                  placeholder="ไลน์ไอดี (LINE ID)"
                  value={form.contact_line_id || ""}
                  onChange={(e) => setForm({ ...form, contact_line_id: e.target.value })}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={6}>
                <input
                  type="text"
                  name="customer_car_model"
                  placeholder="ยี่ห้อรถ"
                  value={form.customer_car_model || ""}
                  onChange={(e) => setForm({ ...form, customer_car_model: e.target.value })}
                />
              </Col>
              <Col xs={6}>
                <input
                  type="text"
                  name="customer_car_license_plate"
                  placeholder="ทะเบียนรถ"
                  value={form.customer_car_license_plate || ""}
                  onChange={(e) => setForm({ ...form, customer_car_license_plate: e.target.value })}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12}>
                <textarea
                  type="text"
                  name="booking_note"
                  placeholder="ความต้องการเพิ่มเติม"
                  value={form.booking_note || ""}
                  onChange={(e) => setForm({ ...form, booking_note: e.target.value })}
                />
              </Col>
            </Row>
          </div>
          {addonOptions && addonOptions.length > 0 && <div className="mt-2">
            <strong className="text-warning">รายการอุปกรณ์เสริม(ถ้ามี)</strong>
            <div className="mb-3">
              {addons.map((ad, i) => {
                return (
                  <div className="mt-3" key={`addons-list-${i}`}>
                    <div className="d-flex mb-3 ">
                      <div className="top-label" style={{ flex: 1 }}>
                        <Form.Label htmlFor={`addons_name_${i}`}>อุปกรณ์เสริม{i + 1}</Form.Label>
                        <Select
                          options={addonOptions}
                          onChange={(option) => {
                            let ads = [...addons]
                            ad.addons_id = option.value
                            ad.unit_price = option._price
                            ad.total_price = ((Number(option._price) * Number(ad.qty)) || 0)
                            ads[i] = ad
                            setAddons(ads)
                          }}
                          className={'flex-fill'}
                          placeholder="เลือกอุปกรณ์เสริม"
                          value={optionValue(ad.addons_id, addonOptions)}
                          classNamePrefix="react-select"
                          id={`addons_name_${i}`}
                        />
                      </div>
                      <div className="top-label ms-2" style={{ width: 70 }}>
                        <Form.Label htmlFor={`addons_qty_${i}`}>จำนวน</Form.Label>

                        <Form.Control id={`addons_qty_${i}`} type="number" min="1" onKeyPress={inputKeyPressOnlyNumber} value={ad.qty || ""} onChange={(e) => {
                          let ads = [...addons]
                          ad.qty = e.target.value
                          ad.total_price = Number(e.target.value) * Number(ad.unit_price)
                          ads[i] = ad
                          setAddons(ads)
                        }} />
                      </div>
                      <div className="top-label ms-2" style={{ width: 100 }}>
                        <Form.Label htmlFor={`addons_price_${i}`}>ราคา</Form.Label>
                        <Form.Control id={`addons_price_${i}`} type="text" value={numberFormat(ad.total_price) || ""} disabled />
                      </div>
                      <Button variant="outline-danger" disabled={submitting} className="btn-icon btn-icon-only ms-2" onClick={() => {
                        let ads = [...addons]
                        ads.splice(i, 1)
                        setAddons(ads)
                      }}>
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  </div>
                )
              })}
              {addons && addons.length > 0 && <p className="text-primary">รวมค่าบริการอุปกรณ์เสริม {numberFormat((sumAddons || 0), true)} บาท
                {(form.payment_type === 'deposit' && !!currentHotel?.deposit_addon_enabled) && addonPriceForPay > 0 && <small className="text-muted ms-2">(มัดจำ {numberFormat(addonPriceForPay, true)} บาท)</small>}
              </p>}
              <Button variant="light" disabled={submitting} className="btn-icon btn-sm" onClick={() => {
                let ads = [...addons]
                ads.push({ name: '', qty: 1, total_price: 0, unit_price: 0 })
                setAddons(ads)
              }}>
                <i className="fa fa-plus me-2" />
                เพิ่มอุปกรณ์เสริม
              </Button>
            </div>
          </div>}
          {!!acceptPdpa && <div>
            <hr />
            {!!currentHotel.payment_info_th && <div>
              <p className="mb-2 text-black">ช่องทางการชำระเงิน</p>
              <div>
                <div dangerouslySetInnerHTML={{ __html: currentHotel.payment_info_th }} />
              </div>
            </div>}
            <div className="d-flex mb-2 align-items-end mt-3">
              <div>
                <label htmlFor={`form_file_slip`} className="form-label">แนบหลักฐานการชำระเงิน</label>
                <input accept="image/*" className="form-control" id={`form_file_slip`} type="file" onChange={(e) => {
                  if (e?.target?.files && e?.target?.files[0]) {
                    setForm({ ...form, slip_file: e.target.files[0] })
                  }
                }} />
              </div>
              <div className="ms-2" style={{ width: 40 }}>
                {!!form.slip_file &&
                  <img src={URL.createObjectURL(form.slip_file)} className="rounded-sm" style={{ width: 40, height: 40 }} onClick={() => {
                    setIsLightboxOpen(true)
                  }} />}
                {/* {!!p?.attached_slip && !p.image &&
                  <img src={p.attached_slip} className="rounded-sm" style={{ width: 40, height: 40 }} onClick={() => {
                    setImageForPreview(p.attached_slip)
                    setIsLightboxOpen(true)
                  }} />} */}
              </div>
            </div>
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <h4 style={{ flex: 1 }}>จำนวนที่ต้องชำระ : {numberFormat(payAmount)} บาท</h4>
            <div>
              <div className="tp-purchase-btn py-3 px-5 rounded" onClick={() => {
                if (!acceptPdpa) {
                  ReactGA.event({
                    category: "BookingProcess",
                    action: `${currentHotel?.code}_${roomSelected?.room_no}_pdpa_click`,
                    label: `Click for PDPA Modal - ${currentHotel?.name_th} : ${roomSelected?.name_th}`,
                  });
                } else {
                  ReactGA.event({
                    category: "Booking Submit Button",
                    action: `${currentHotel?.code}_${roomSelected?.room_no}_submit_booking`,
                    label: `Click for Submit Booking - ${currentHotel?.name_th} : ${roomSelected?.name_th}`,
                  });
                }

                if (!acceptPdpa) {
                  setShowPolicyModal(true)
                } else {

                  if (submitting) {
                    return
                  }

                  if (dayQty < 1) {
                    toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
                    return
                  }

                  if (!form.contact_name) {
                    toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
                    return
                  }

                  if (!form.contact_tel_1) {
                    toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
                    return
                  }

                  if (!validateAddons()) {
                    toast.warn('กรุณาเลือกอุปกรณ์เสริมให้ครบถ้วน หรือลบรายการที่ไม่ต้องการออก')
                    return
                  }

                  if (!totalPrice || totalPrice < 1) {
                    toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
                    return
                  }

                  if (payAmount > 0 && !form.slip_file) {
                    toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
                    return
                  }

                  if (!form.guest_qty && form.guest_qty < 1) {
                    toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
                    return
                  }

                  setSubmitting(true)

                  const rawForm = {
                    ...form,
                    day_qty: dayQty,
                    room_name: roomSelected?.name_th || '',
                    guest_child_qty: form.guest_child_qty || 0,
                    guest_baby_qty: form.guest_baby_qty || 0,
                    tent_qty: form.tent_qty || 0,
                    total_price: totalPrice,
                    discount_code: couponCode,
                    total_discount: discountPrice || 0,
                    start_date: moment(form.start_date).format('YYYY-MM-DD'),
                    end_date: moment(form.end_date).subtract(1, 'days').format('YYYY-MM-DD'),
                    deposit_amount: form.payment_type === 'deposit' ? depositPrice : 0,
                    deposit_addon_type: (form.payment_type === 'deposit' && !!currentHotel?.deposit_addon_enabled) ? 'deposit' : 'full',
                    deposit_addon_amount: (form.payment_type === 'deposit' && !!currentHotel?.deposit_addon_enabled) ? Number(addonPriceForPay) : 0,
                    room_deposit_amount: roomDepositPrice || 0,
                  }

                  const formData = new FormData();
                  for (var key in rawForm) {
                    if (key !== 'slip_file') {
                      formData.append(key, rawForm[key]);
                    }
                  }
                  formData.append("file", rawForm.slip_file);

                  if (addons && addons.length) {
                    addons.map((addonObj, i) => {
                      formData.append(`booking_addons[${i}]`, JSON.stringify(addonObj));
                    })
                  }

                  bookingData.map((b, i) => {
                    formData.append(`booking_items[${i}]`, JSON.stringify(b));
                  })

                  api.request(`bookings/bookingformweb`, (res, success) => {
                    setSubmitting(false)

                    if (!success) {
                      return
                    } else {
                      toast.success('ทำการจองเรียบร้อยแล้ว')
                      handleSetRoomSelected({})
                      navigate(`/once/status/${res.id}/${res.booking_no}`)
                    }
                  }, formData, 'POST')
                }
              }}>
                {acceptPdpa ? 'ยืนยันการจอง' : 'ถัดไป'}{" "}
                <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {/* <Button variant="secondary">ถัดไป</Button> */}
        </Modal.Footer>
      </Modal>
      <PolicyConfirmModal
        show={showPolicyModal}
        onHide={() => setShowPolicyModal(null)}
        hotel_id={roomSelected?.hotel_id}
        onAcceptTerm={() => {
          ReactGA.event({
            category: "BookingProcess",
            action: `${currentHotel?.code}_${roomSelected?.room_no}_cf_booking_click`,
            label: `Click for Confirm Booking - ${currentHotel?.name_th} : ${roomSelected?.name_th}`,
          });

          setAcceptPdpa(true)
          setShowPolicyModal(false)
        }}
      />

      <Modal size="sm" show={showConfrimModal} onHide={() => setShowConfrimModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ห้องพักเต็ม !</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>กรุณาเลือกห้องพักอื่นๆ หรือเลือกวันเข้าพักใหม่</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setForm({ ...form, start_date: new Date(), end_date: new Date() })
            setShowConfrimModal(false)
          }}>ปิด</Button>
          <Button variant="primary" onClick={() => {
            setShowConfrimModal(false)
            onHide()
            setTimeout(() => {
              setShowRoomList(true)
            }, 600)
          }}>เลือกห้องพักอื่น</Button>
        </Modal.Footer>
      </Modal>
      <RoomListModal
        show={showRoomList}
        onHide={() => setShowRoomList(false)}
      />
      {isLightboxOpen && !!form.slip_file && (
        <Lightbox
          medium={URL.createObjectURL(form.slip_file)}
          large={URL.createObjectURL(form.slip_file)}
          alt={'Payment Slip'}
          onClose={() => {
            setIsLightboxOpen(false)
          }}
          hideDownload
          hideZoom
        />
      )}
    </>
  );
};

export default BookingModal;
