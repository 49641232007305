import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber } from './../../utils/functions';
import { toast } from 'react-toastify';
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import ReactGA from 'react-ga4';

const weekendDays = [
  { label: 'อาทิตย์', value: 'sun' },
  { label: 'จันทร์', value: 'mon' },
  { label: 'อังคาร', value: 'tue' },
  { label: 'พุธ', value: 'wed' },
  { label: 'พฤหัสบดี', value: 'thu' },
  { label: 'ศุกร์', value: 'fri' },
  { label: 'เสาร์', value: 'sat' },
]

export default function VoucherOverview() {
  const { code } = useParams();
  const [voucherInfo, setVoucherInfo] = useState({});

  const fetchVoucherData = () => {
    if (!code) {
      return
    }

    api.request(`vouchers/items/findwithcriteria`, (data) => {
      setVoucherInfo(data || {})

      if (!!data.voucher?.hotel) {
        ReactGA.send({ hitType: "pageview", page: `${data.voucher?.hotel?.code}/voucherinfo`, title: `voucher info from qr code` });
      }
    }, {
      enabled: true,
      generate_code: code,
    })
  }

  useEffect(() => {
    if (!!code) {
      fetchVoucherData()
    }
  }, [code])

  return (
    <>
      <div className="text-center text-black">
        {!voucherInfo?.id && <div className="p-5">
          <h4>ไม่พบข้อมูล Voucher !</h4>
          <div>{'กรุณาตรวจสอบความถูกต้องของ Voucher หรือติดต่อที่พัก'}</div>
        </div>}

        {!!voucherInfo?.id && <div className="p-5 booking-status">
          <h4>รายละเอียด Voucher</h4>
          <div className="text-start px-2 px-md-5 py-3">
            <div className="d-flex">
              <h5 className="p-2 mb-0" style={{ backgroundColor: '#000000', color: '#ffffff', borderRadius: 5 }} >VOUCHER NO. {voucherInfo?.generate_code}</h5>
              <Button className="btn btn-sm px-2 ms-2" variant="outline-danger" onClick={()=> {
                navigator.clipboard.writeText(voucherInfo?.generate_code)
                toast.success('คัดลอกรหัสเรียบร้อยแล้ว')
              }}>คัดลอกรหัส</Button>
            </div>
            <h4 className="mt-2">GIFT Voucher : {voucherInfo.voucher?.name}</h4>
            <hr style={{ color: '#000000' }} />
            <div className="d-flex mt-1">
              <h5 className="font-weight-bold">ที่พักจำนวน {numberFormat(voucherInfo.voucher?.day_qty || 1, true)} คืน - {voucherInfo.voucher?.hotel?.name_th}</h5>
            </div>
            {voucherInfo.voucher?.room_map_type === 'all' && <div className="d-flex mt-1">
              <h5>*** คูปองนี้ใช้ได้กับทุกห้องพัก</h5>
            </div>}
            {voucherInfo.voucher?.room_map_type === 'type' && <div className="d-flex mt-1">
              <h5>ประเภทห้องพัก</h5>
              <div>
                {voucherInfo.voucher?.room_type_items?.length > 0 && voucherInfo.voucher?.room_type_items.map((r, i) => {
                  return (
                    <div key={`room-type-${i}-${voucherInfo?.generate_code}`}><i className="fa fa-check-square ms-3 me-2" />{r.name_th}</div>
                  )
                })}
              </div>
            </div>}
            {voucherInfo.voucher?.room_map_type === 'room' && <div className="d-flex mt-1">
              <h5>ห้องพัก</h5>
              <div>
                {voucherInfo.voucher?.room_items?.length > 0 && voucherInfo.voucher?.room_items.map((r, i) => {
                  return (
                    <div key={`room-${i}-${voucherInfo?.generate_code}`}><i className="fa fa-check-square ms-3 me-2" />{r.name_th}</div>
                  )
                })}
              </div>
            </div>}
            <div className="d-flex mt-2 align-items-center">
              <i className="far fa-calendar me-3" />
              {voucherInfo.voucher?.day_for_stay_type === 'all' && <span>สามารถเข้าพักได้ทุกวัน</span>}
              {voucherInfo.voucher?.day_for_stay_type === 'holiday' && <span>วันหยุดนักขัตฤกษ์</span>}

              {voucherInfo.voucher?.day_for_stay_type === 'daywithholiday' && voucherInfo.voucher?.day_for_stay?.length > 0 && voucherInfo.voucher?.day_for_stay.map((d, i) => {
                return (
                  <div className="d-flex  align-items-center">
                    <span key={`day-for-stay-${i}-${voucherInfo?.generate_code}`}>{weekendDays.find((w) => w.value === d)?.label}</span>
                    <div className="mx-2" style={{ width: 8, height: 8, backgroundColor: '#000000', borderRadius: 4 }}></div>
                  </div>
                )
              })}
              {voucherInfo.voucher?.day_for_stay_type === 'daywithholiday' && <span>วันหยุดนักขัตฤกษ์</span>}

              {voucherInfo.voucher?.day_for_stay_type === 'day' && voucherInfo.voucher?.day_for_stay?.length > 0 && voucherInfo.voucher.day_for_stay.map((d, i) => {
                return (
                  <div className="d-flex align-items-center">
                    <span key={`day-for-stay-${i}-${voucherInfo?.generate_code}`}>{weekendDays.find((w) => w.value === d)?.label}</span>
                    {i < (voucherInfo.voucher?.day_for_stay.length - 1) && <div className="mx-2" style={{ width: 8, height: 8, backgroundColor: '#000000', borderRadius: 4 }}></div>}
                  </div>
                )
              })}
            </div>
            <div className="mt-3">
              <h5>เงื่อนไขการใช้ Voucher</h5>
              <div className="ps-3">
                <ul>
                  {!!voucherInfo.voucher?.use_start_date && !!voucherInfo.voucher?.use_expire_date && <li style={{ listStyle: 'inherit' }}>ทำรายการจองได้ตั้งแต่ {moment(voucherInfo.voucher?.use_start_date).format('DD/MM/YYYY')} - {moment(voucherInfo.voucher?.use_expire_date).format('DD/MM/YYYY')} </li>}
                  {!!voucherInfo.voucher?.use_start_date && !voucherInfo.voucher?.use_expire_date && <li style={{ listStyle: 'inherit' }}>ทำรายการจองได้ตั้งแต่ {moment(voucherInfo.voucher?.use_start_date).format('DD/MM/YYYY')} </li>}
                  {!voucherInfo.voucher?.use_start_date && !!voucherInfo.voucher?.use_expire_date && <li style={{ listStyle: 'inherit' }}>ทำรายการจองได้ถึงวันที่ {moment(voucherInfo.voucher?.use_expire_date).format('DD/MM/YYYY')} </li>}

                  {!!voucherInfo.voucher?.stay_start_date && !!voucherInfo.voucher?.stay_enddate_date && <li style={{ listStyle: 'inherit' }}>เข้าพักได้ตั้งแต่ {moment(voucherInfo.voucher?.stay_start_date).format('DD/MM/YYYY')} - {moment(voucherInfo.voucher?.stay_enddate_date).format('DD/MM/YYYY')} </li>}
                  {!!voucherInfo.voucher?.stay_start_date && !voucherInfo.voucher?.stay_enddate_date && <li style={{ listStyle: 'inherit' }}>เข้าพักได้ตั้งแต่ {moment(voucherInfo.voucher?.stay_start_date).format('DD/MM/YYYY')} </li>}
                  {!voucherInfo.voucher?.stay_start_date && !!voucherInfo.voucher?.stay_enddate_date && <li style={{ listStyle: 'inherit' }}>เข้าพักได้ถึงวันที่ {moment(voucherInfo.voucher?.stay_enddate_date).format('DD/MM/YYYY')} </li>}

                  {!!voucherInfo.voucher?.exclude_day_for_stay && !!voucherInfo.voucher?.exclude_day_for_stay.length > 0 && <li style={{ listStyle: 'inherit' }}><span className="text-danger font-weight-bold">ไม่สามารถเข้าพักได้ในวันที่</span> {voucherInfo.voucher?.exclude_day_for_stay.map((d) => moment(d).format('DD/MM/YYYY')).join(', ')} </li>}
                  {(voucherInfo.voucher?.room_map_type === 'all' && !!voucherInfo.voucher?.price_limit && voucherInfo.voucher?.price_limit > 0) && <li style={{ listStyle: 'inherit' }}>มูลค่าการใช้ Voucher ไม่เกิน {numberFormat(voucherInfo.voucher?.price_limit, true)} บาท</li>}
                </ul>
              </div>
            </div>
          </div>
        </div>}
      </div >
      <OnceFooter />
    </>
  );
}
