import React, { useState, useRef, useEffect, useContext } from "react";
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Lightbox } from "react-modal-image";
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import _ from "lodash";

const ShowBookingInfo = ({ show, onHide = () => { }, booking_id = null, is_admin = false }) => {
  const [booking, setBooking] = useState({})
  const [imageForPreview, setImageForPreview] = useState(null);

  const fetchItem = () => {
    api.request(`bookings/findonebooking`, (data) => {
      setBooking(data || {})
    }, {
      id: booking_id
    })
  }

  useEffect(() => {
    if (!show || !booking_id) {
      setBooking({})
      return
    }

    fetchItem()
  }, [show])

  const endDate = new Date(booking?.end_date)
  const endDateStr = endDate.setDate(endDate.getDate() + 1)

  const addons = booking?.booking_addons || []
  const addonsText = addons.map((x) => { return `${x?.addons_type?.name_th} (${x?.qty})` })
  const addonsTextStr = (addonsText || []).join(', ')
  const addonsSum = _.sumBy(addons || [], (x) => { return Number(x.total_price || 0) }) || 0

  const items = booking?.booking_items || []
  const bookingDatesStr = items.map((b) => `${moment(b.date).format('DD/MM/YYYY')} (${numberFormat(b.unit_total_price, true)})`).join(', ')

  const outstanding = booking.payment_type === 'full' ? 0 : (Number(booking.net_total || 0) - Number(booking.paid_amount || 0))

  let paySlips = []

  if (booking.booking_payments && booking.booking_payments.length) {
    paySlips = (booking.booking_payments.filter(x => !!x.attached_slip) || []).map((item) => {
      if (!!item.attached_slip) {
        return item.attached_slip
      }
    }) || []
  }


  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static">
        <Modal.Header closeButton className="p-3">
          <Modal.Title>รายละเอียดการจองห้องพัก</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1 booking-status">
          {!!booking.id && <div className="text-start p-2">
            <Row>
              {!!is_admin && <Col xs={12}>
                <div className="d-flex my-2" style={{ height: 40 }}>
                  {paySlips.map((l, li) => {
                    return (
                      <img style={{ width: 40, height: 40 }} key={`slip-img-${li}`} src={l} className="rounded-sm img-thumb me-2 shadow-sm" alt={'payment slip'} onClick={() => setImageForPreview(l)} />
                    )
                  })}
                  {!!booking.receipt_file_path && <img style={{ width: 40, height: 40 }} src={booking.receipt_file_path} className="rounded-sm img-thumb shadow-sm" alt={'Booking Document'} onClick={() => setImageForPreview(booking.receipt_file_path)} />}
                </div>
              </Col>}
              <Col xs={12}>
                <span className="title">ห้องพัก : </span>
                <span className="detail">{booking?.room?.name_th || booking?.campType?.name_th || 'เช่าพื้นที่กางเต็นท์/Car Camp/Walk - In'}</span>
              </Col>
              <Col xs={12}>
                <span className="title">วันที่เข้าพัก : </span>
                <span className="detail">{moment(booking?.start_date).format('DD/MM/YYYY')} - {moment(endDateStr).format('DD/MM/YYYY')} ({booking?.day_qty} คืน)</span>
              </Col>
              <Col xs={12}>
                <span className="title">ผู้เข้าพัก : </span>
                <span className="detail">ผู้ใหญ่ {numberFormat(booking?.guest_qty, true)} ท่าน, เด็ก 6 - 12 ปี {numberFormat(booking?.guest_child_qty, true)} ท่าน, เด็กต่ำกว่า 6 ปี {numberFormat(booking?.guest_baby_qty, true)} ท่าน{booking?.tent_qty > 0 ? `, จำนวนเต็นท์ ${numberFormat(booking?.tent_qty, true)} หลัง` : ''}</span>
              </Col>
              <Col xs={12} md={6}>
                <span className="title">ชื่อผู้ติดต่อ : </span>
                <span className="detail">{booking?.contact_name}</span>
              </Col>
              <Col xs={12} md={6}>
                <span className="title">หมายเลขโทรศัพท์ : </span>
                <span className="detail">{booking?.contact_tel_1} {!!booking?.contact_tel_2 ? `/ ${booking?.contact_tel_2}` : ''}</span>
              </Col>
              <Col xs={12} md={6}>
                <span className="title">อีเมล : </span>
                <span className="detail">{booking?.contact_email || '-'}</span>
              </Col>
              <Col xs={12} md={6}>
                <span className="title">ไลน์ไอดี (Line ID) : </span>
                <span className="detail">{booking?.contact_line_id || '-'}</span>
              </Col>
              <Col xs={12} md={6}>
                <span className="title">ยี่ห้อรถ : </span>
                <span className="detail">{booking?.customer_car_model || '-'}</span>
              </Col>
              <Col xs={12} md={6}>
                <span className="title">ทะเบียนรถ : </span>
                <span className="detail">{booking?.customer_car_license_plate || '-'}</span>
              </Col>
              {!!is_admin && <Col xs={12} md={6}>
                <span className="title">ราคา : </span>
                <span className="detail">{numberFormat(booking?.total_price)} บาท {(items?.length > 0 && booking.room_id) && <small className="text-muted">- {bookingDatesStr}</small>}</span>
              </Col>}
              {!!is_admin && <Col xs={12} md={6}>
                <span className="title">ส่วนลด : </span>
                <span className="detail">{numberFormat(booking?.total_discount)} บาท {Number(booking?.total_discount) > 0 && booking?.discount_coupon_code ? `(${booking?.discount_coupon_code})` : ''}</span>
              </Col>}
              {!!is_admin && <Col xs={12} md={6}>
                <span className="title">ประเภทการชำระเงิน : </span>
                <span className="detail">{booking.payment_type === 'full' ? 'ชำระเต็มจำนวน' : 'มัดจำ'}</span>
              </Col>}
              {!!is_admin && <Col xs={12} md={6}>
                <span className="title">จำนวนเงินที่ชำระ : </span>
                <span className="detail">{numberFormat(booking?.paid_amount)} บาท</span>
              </Col>}
              {!!is_admin && <Col xs={12} md={6}>
                <span className="title">ค้างชำระ : </span>
                <span className="detail">{numberFormat(outstanding)} บาท</span>
              </Col>}
              {!!is_admin && <Col xs={12} md={6}>
                <span className="title">สถานะการชำระเงิน : </span>
                {booking.payment_state === 'waiting' && <span className="badge bg-warning">รอการตรวจสอบ</span>}
                {booking.payment_state === 'waiting-approved' && <span className="badge bg-success">ชำระเงินวันเข้าพัก</span>}
                {booking.payment_state === 'complete' && <span className="badge bg-success">ชำระเงินเรียบร้อยแล้ว</span>}
                {booking.payment_state === 'partial' && <span className="badge bg-info">ชำระเงินมัดจำเรียบร้อยแล้ว</span>}
              </Col>}
            </Row>
            <div>
              <span className="title">หมายเหตุ : </span>
              <span className="detail">{booking?.booking_note || '-'}</span>
            </div>
            {addons.length > 0 && <div>
              <span className="title">อุปกรณ์เสริม : </span>
              <span className="detail">{addonsTextStr || '-'}  ราคารวม {numberFormat(addonsSum)} บาท</span>
            </div>}
          </div>}
        </Modal.Body>
      </Modal>
      {!!imageForPreview && (
        <Lightbox
          medium={imageForPreview}
          large={imageForPreview}
          alt={'Image'}
          onClose={() => {
            setImageForPreview(null)
          }}
          hideDownload
          hideZoom
        />
      )}
    </>
  );
};

export default ShowBookingInfo;
