import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import 'moment/locale/th'
import _ from "lodash";
import BookingPriceSummary from '../Booking/BookingPriceSummary';
import BookingCalendar from "../Booking/BookingCalendar";

export default function CheckCalendarWith3D() {
  const { id } = useParams();
  const { handleSetRoomSelected, currentHotel, handleSetCurrentHotel, roomSelected } = useContext(AppContext);

  const fetchRoomData = () => {
    api.request(`rooms/${id}`, (data) => {
      if (!data) {
        alert("ลิงค์ไม่ถูกต้อง !")
        window.location.replace("https://www.onceinmemory.com");
        return
      }

      handleSetRoomSelected(data)
    }, {})
  }

  useEffect(() => {
    fetchRoomData()
  }, [id])

  return (
    <>
      <div style={{ padding: '10px 25px' }}>
        <div className="d-flex">
          <h4 className="text-black mb-0"> {roomSelected?.name_th}</h4>
          <div className="ms-3">
            <small className="mb-0"><i className="fal fa-user"></i>  {roomSelected?.guest_qty} Persons</small>
            {!!roomSelected?.bath_room_qty && roomSelected?.bath_room_qty > 0 && <small className="ms-2 mb-0"><i className="fal fa-shower"></i>  {roomSelected?.bath_room_qty}</small>}
            {!!roomSelected?.bed_room_qty && roomSelected?.bed_room_qty > 0 && <small className="ms-2 mb-0"><i className="fal fa-bed"></i>  {roomSelected?.bed_room_qty}</small>}
          </div>
        </div>
        {roomSelected?.facilities?.length > 0 && <div className="pb-2">
          {roomSelected?.facilities?.map((h, i) => {
            if (i > 2) return null
            return (
              <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-2">
                <i className="fal fa-check text-success me-2"></i>
                <small className="mb-0">{h.name_th}</small>
              </div>
            )
          })}
          {roomSelected?.facilities?.length > 3 && <div key={`s-room-fac-more`} className="d-inline-block ms-2">
            <i className="fal fa-check text-success me-2"></i>
            <small className="mb-0">อื่นๆ...</small>
          </div>}
        </div>}
        <div className="text-center" style={{ width: 350 }}>
          <BookingCalendar room_id={roomSelected?.id} hotel_id={roomSelected?.hotel_id} sm_calendar={true} />
        </div>

      </div>
      <BookingPriceSummary showWith360={true} />
    </>
  );
}
