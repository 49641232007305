import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import RoomListModal from './../../Room/RoomListModal';
import { AppContext } from "./../../context/ContextProvider";

const Menus = () => {
  const [showRoomList, setShowRoomList] = useState(false)
  const { roomSelected, currentHotel } = useContext(AppContext);

  const video360Link = !!currentHotel ? (currentHotel['360_link'] || null) : null;

  return (
    <>
      <ul>
        {!!video360Link && <li>
          {/* <li className="has-dropdown"> */}
          <a href={video360Link} target="_blank">View 360°</a>
          {/* <Link to="/">ดูห้องพัก</Link>
          <ul className="submenu">
            <li>
              <a href={!!currentHotel ? (currentHotel['360_link'] || 'https://www.onceinmemory.com') : 'https://www.onceinmemory.com'} target="_blank"><i className="fas fa-street-view"></i> View 360°</a>
            </li>
            <li>
              <a href="#!" onClick={() => setShowRoomList(true)}><i className="fas fa-list"></i> ดูห้องพักทั้งหมด</a>
            </li>
          </ul> */}
        </li>}
        <li>
          <a href="#gallery">GALLERY</a>
        </li>
        <li>
          <a href="#video">VIDEO</a>
        </li>
        <li>
          <a href="#map"><i className="fas fa-map-marked-alt"></i></a>
        </li>
      </ul>
      <RoomListModal
        show={showRoomList}
        onHide={() => setShowRoomList(false)}
      />
    </>
  );
};

export default Menus;
