import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api, { WEB_BASE_URL } from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber } from './../../utils/functions';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import 'moment/locale/th'
import _, { set } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ShowBookingInfo from "./ShowBookingInfo";

export default function BookingOverview() {
  const { hotel_id } = useParams();
  const { setDummyToken, getDummyToken } = useContext(AppContext);

  const [hotel, setHotel] = useState({})
  const [bookings, setBookings] = useState([])

  const [mainCurrentStartDate, setMainCurrentStartDate] = useState(new Date());
  const [blockdays, setBlockdays] = useState([]);

  const [showBookingsInfo, setShowBookingsInfo] = useState(false);
  const [bookingsForShow, setBookingsForShow] = useState([]);
  const [blockdaysForShow, setBlockdaysForShow] = useState([]);
  const [closeAllDataForShow, setCloseAllDataForShow] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [_tokenKey, setTokenKey] = useState(null);
  const [bookingIdForShow, setBookingIdForShow] = useState(null);

  const a = moment(new Date(moment(endDate).format('YYYY-MM-DD')));
  const b = moment(new Date(moment(startDate).format('YYYY-MM-DD')));
  const dayQty = a.diff(b, 'days') || 0

  const checkToken = getDummyToken()

  const verifyToken = () => {
    if (!checkToken || !checkToken.length || !hotel_id) {
      return false
    }

    const hotelIdStr = hotel_id.replace(/-/g, '')
    if (checkToken !== `${hotelIdStr}gVi3NKTzFwBn`) {
      return false
    }

    return true
  }

  const fetchHotel = () => {
    if (!hotel_id) {
      return
    }

    api.request(`hotels/${hotel_id}`, (data) => {
      if (data) {
        setHotel(data)
      }
    }, {})
  }

  const fetchฺBookingFromDate = (currentDate) => {
    if (!currentDate) {
      setBookings([])
      return
    }

    api.request('bookings/all-check-room', (data) => {
      setBookings(data || [])
    }, {
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchฺBlockdayFromDate = (currentDate) => {
    if (!currentDate) {
      setBlockdays([])
      return
    }

    api.request('bookings/all-check-room-blockday', (data) => {
      if (data && data.length) {
        setBlockdays(data.map((item) => {
          return {
            date: item.date,
            room_id: item.room_id,
            room_name: item.room?.name_th,
            note: item.note,
          }
        }) || [])
      } else {
        setBlockdays([])
      }
    }, {
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  useEffect(() => {
    if (!hotel_id) {
      alert('ลิงค์ไม่ถูกต้อง !')
      return
    }

    if (!verifyToken()) {
      alert('Key ไม่ถูกต้อง !')
      return
    }

    fetchHotel()
    fetchฺBookingFromDate(mainCurrentStartDate)
    fetchฺBlockdayFromDate(mainCurrentStartDate)
  }, [checkToken])

  useEffect(() => {
    if (!hotel_id) {
      return
    }

    if (!!mainCurrentStartDate) {
      fetchฺBookingFromDate(mainCurrentStartDate)
      fetchฺBlockdayFromDate(mainCurrentStartDate)
    }
  }, [mainCurrentStartDate])

  const checkCloseAll = (date) => {
    const hasClose = bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0
  }

  const getCloseAllData = (date) => {
    const hasClose = bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0 ? hasClose[0] : null
  }

  const checkBooking = (date) => {
    const hasBooking = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    const carBooking = hasBooking.filter((item) => {
      return !!item.room_id && !item.is_walkin
    })

    const walkinBooking = hasBooking.filter((item) => { return item.is_walkin })

    return {
      all: hasBooking.length || 0,
      car: carBooking.length || 0,
      carNames: carBooking.length > 0 ? ((carBooking.map((b) => { return b.room?.name_th }) || []).join(', ')) : '',
      walkin: walkinBooking.length || 0,
    }
  }

  const getBookingsInfo = (date) => {
    const hasBookings = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    return hasBookings.sort((a, b) => {
      return a.is_walkin ? 1 : -1
    })
  }

  const getBlockdayInfo = (date) => {
    return blockdays.filter((item) => {
      return date === item.date
    }) || []
  }

  const checkTokenKeyInput = () => {
    if (!hotel_id || !_tokenKey || !_tokenKey.length) {
      return
    }

    const hotelIdStr = hotel_id.replace(/-/g, '')

    if (_tokenKey !== `${hotelIdStr}gVi3NKTzFwBn`) {
      alert('Key ไม่ถูกต้อง !')
      return
    }

    setDummyToken(_tokenKey)
    window.location.reload()
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date', 'px-1'];
    const isPartDate = moment(date).isBefore(moment(), 'day')
    const dateStr = moment(date).format('YYYY-MM-DD')

    // if (!isPartDate) {
    if (moment(date).isoWeekday() === 5 || moment(date).isoWeekday() === 6) {
      classes.push('custom-weekend');
    }

    if (checkCloseAll(dateStr)) {
      classes.push('custom-close-all');
    }

    const checkBookingData = checkBooking(dateStr)
    if (checkBookingData.all > 0) {
      classes.push('custom-has-booking');
    }

    const checkBlockdayData = getBlockdayInfo(dateStr)
    if (checkBlockdayData.length > 0) {
      classes.push('custom-close-room');
    }

    if (isPartDate) {
      classes.push('custom-part-date');
    }
    // } else {
    //   classes.push('part-date');
    // }

    return classes.join(' ');
  }

  if (!hotel_id || !checkToken || !checkToken.length || !verifyToken()) {
    return (
      <div className="p-4">
        <div className="text-start" style={{ width: 250 }}>
          <form className="form-group" onSubmit={(e) => { e.preventDefault(); checkTokenKeyInput() }}>
            <input type="text" className="form-control" placeholder="Enter Key" value={_tokenKey || ''} onChange={(e) => setTokenKey(e.target.value)} />
            <button disabled={!_tokenKey || !_tokenKey.length} type="submit" className="btn btn-sm btn-primary mt-2">Submit</button>
          </form>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="text-center">
        <div className="px-5 py-3">
          <div className="text-start pb-2">
            <h5 className="text-black">BOOKING OVERVIEW : {hotel?.name_th}</h5>
          </div>
          <div className="booking-form-input">
            <div className="mt-2">
              <div className={`mt-2 mb-4`} style={{ maxWidth: 500 }}>
                <div className="mb-2 d-flex title-btn">
                  <span onClick={() => setMainCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
                </div>
                <Calendar
                  showDoubleView={false}
                  // minDate={new Date()}
                  calendarType={'hebrew'}
                  locale={'th-TH'}
                  activeStartDate={mainCurrentStartDate}
                  showNavigation={true}
                  tileClassName={tileClassName}
                  tileContent={({ activeStartDate, date, view }) => {
                    let priceStr = ''
                    const dateStr = moment(date).format('YYYY-MM-DD')

                    const checkBlockdayData = getBlockdayInfo(dateStr)
                    if (checkBlockdayData.length > 0) {
                      priceStr = 'Block Day'
                    }

                    if (checkCloseAll(dateStr)) {
                      priceStr = 'เหมาลาน'
                    }

                    const checkBookingData = checkBooking(dateStr)

                    if (checkBookingData.all > 0) {
                      priceStr = checkBookingData.all + ' การจอง'
                    }

                    return (view === 'month') ?
                      (
                        <div className="price-container">
                          {checkBookingData.all === 0 && <p className={`booking-calendar-price`}>{priceStr}</p>}
                          {checkBookingData.all > 0 && <p className={`booking-calendar-price`}>
                            {checkBookingData.car > 0 && <span><i className="fal fa-house-user"></i> {checkBookingData.car}</span>}
                            {(checkBookingData.car > 0 && checkBookingData.walkin > 0) && <span className="ps-1"></span>}
                            {checkBookingData.walkin > 0 && <span><i className="fal fa-campground"></i> {checkBookingData.walkin}</span>}
                          </p>
                          }
                        </div>
                      )
                      : null
                  }}
                  value={null}
                  onActiveStartDateChange={(s) => {
                    if (s?.activeStartDate) {
                      setMainCurrentStartDate(s.activeStartDate)
                    }
                  }}
                  onClickDay={(date) => {
                    const dateStr = moment(date).format('YYYY-MM-DD')

                    const checkCloseAllData = checkCloseAll(dateStr)
                    if (checkCloseAllData) {
                      setCloseAllDataForShow(getCloseAllData(dateStr))
                      setShowBookingsInfo(true)
                      return
                    }

                    const checkBookingData = checkBooking(dateStr)
                    if (checkBookingData.all > 0) {
                      setBookingsForShow(getBookingsInfo(dateStr))
                    }

                    const checkBlockdayData = getBlockdayInfo(dateStr)
                    setBlockdaysForShow(checkBlockdayData || [])

                    if (checkBookingData.all > 0 || checkBlockdayData.length > 0) {
                      setShowBookingsInfo(true)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showBookingsInfo} onHide={() => {
        setShowBookingsInfo(false)
        setBookingsForShow([])
        setBlockdaysForShow([])
        setCloseAllDataForShow(null)
      }} backdrop="static">
        <Modal.Header closeButton className="p-3">
          <Modal.Title>รายละเอียดการจองห้องพัก</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1 booking-status">
          <div className="text-start p-0">
            {!closeAllDataForShow && <table className="table table-striped">
              <tbody>
                {bookingsForShow?.length > 0 && bookingsForShow.map((b, i) => {
                  const icon = b.is_walkin ? 'fal fa-campground' : 'fal fa-house-user'
                  const walkinPerson = b.is_walkin ? Number(b.guest_qty || 0) + Number(b.guest_child_qty || 0) : 0

                  return (
                    <tr key={`booking-info-${i}`}>
                      <td>
                        <div className="d-flex align-items-center" onClick={()=> setBookingIdForShow(b.id)}>
                          <i className={icon}></i>
                          <div className="ms-2">
                            {!b.is_walkin && <div>{b.room?.name_th}</div>}
                            {!!b.is_walkin && <div>{numberFormat(walkinPerson, true)} ท่าน</div>}
                          </div>
                        </div>
                      </td>
                      <td>{b.contact_name}</td>
                      <td>{b.contact_tel_1}</td>
                    </tr>
                  )
                })}
                {blockdaysForShow?.length > 0 && blockdaysForShow.map((b, i) => {
                  return (
                    <tr key={`booking-info-${i}`}>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className={"text-danger fal fa-times-circle"}></i>
                          <div className="ms-2">
                            <div className="text-danger ">{b.room_name}</div>
                          </div>
                        </div>
                      </td>
                      <td colSpan={2}><small className="text-muted">{b.note}</small> </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>}
            {!!closeAllDataForShow && <div className="p-3">
              <p className="text-primary mb-2">ลูกค้าเหมาลาน</p>
              <ul>
                <li><span className="text-black">ชื่อผู้ติดต่อ : </span> {closeAllDataForShow.contact_name}</li>
                <li><span className="text-black">หมายเลขโทรศัพท์ : </span> {closeAllDataForShow.contact_tel_1}</li>
                <li><span className="text-black">หมายเหตุ : </span> {closeAllDataForShow.booking_note || '-'}</li>
              </ul>
            </div>}
          </div>
        </Modal.Body>
      </Modal>
      <ShowBookingInfo show={!!bookingIdForShow} onHide={() => setBookingIdForShow(null)} booking_id={bookingIdForShow} is_admin={true} />
      <OnceFooter />
    </>
  );
}
